document.addEventListener('DOMContentLoaded', function () {
  const shopLink = document.getElementById('shop-link')
  const megaMenu = document.getElementById('mega-menu')
  const menuLinks = document.querySelectorAll('#primary-menu a')

  function openMegaMenu() {
    if (megaMenu.classList.contains('hidden')) {
      megaMenu.classList.remove('hidden')
      setTimeout(() => {
        megaMenu.classList.add('flex')
        megaMenu.classList.remove('scale-y-0', 'opacity-0')
        megaMenu.classList.add('scale-y-100', 'opacity-100')
      }, 10)
    }
  }

  function closeMegaMenu() {
    megaMenu.classList.remove('flex')
    megaMenu.classList.add('scale-y-0', 'opacity-0')
    megaMenu.classList.remove('scale-y-100', 'opacity-100')
    megaMenu.classList.add('hidden')
  }

  if (shopLink && megaMenu) {
    shopLink.addEventListener('click', function (event) {
      event.preventDefault()
      if (megaMenu.classList.contains('hidden')) {
        openMegaMenu()
      } else {
        closeMegaMenu()
      }
    })

    shopLink.addEventListener('mouseover', function () {
      openMegaMenu()
    })

    megaMenu.addEventListener('mouseleave', function () {
      closeMegaMenu()
    })

    menuLinks.forEach((link) => {
      if (link !== shopLink) {
        link.addEventListener('mouseover', function () {
          closeMegaMenu()
        })
      }
    })
  }
})
