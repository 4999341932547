document.addEventListener('DOMContentLoaded', function () {
  function updateCart(itemKey, newQty) {
    const ajaxUrl = `${window.location.origin}/wp/wp-admin/admin-ajax.php`
    const data = new URLSearchParams({
      action: 'woocommerce_update_cart_item',
      cart_item_key: itemKey,
      new_qty: newQty
    })

    // Add class to cart to indicate loading
    document.querySelector('.woocommerce-mini-cart').classList.add('updating-cart')

    fetch(ajaxUrl, {
      method: 'POST',
      body: data
    })
      .then((response) => response.json())
      .then((response) => {
        // Trigger a fragment refresh to update cart content
        document.body.dispatchEvent(new CustomEvent('wc_fragment_refresh'))

        // Update the total amount
        if (
          response &&
          response.fragments &&
          response.fragments['.woocommerce-mini-cart__total .amount']
        ) {
          document.querySelector('.woocommerce-mini-cart__total .amount').innerHTML =
            response.fragments['.woocommerce-mini-cart__total .amount']
        }

        // Remove the updating class
        document.querySelector('.woocommerce-mini-cart').classList.remove('updating-cart')
      })
      .catch(() => {
        // Remove the updating class in case of error as well
        document.querySelector('.woocommerce-mini-cart').classList.remove('updating-cart')
      })
  }

  document.querySelector('.woocommerce-mini-cart').addEventListener('change', function (event) {
    if (event.target.classList.contains('qty')) {
      const cart_item_key = event.target.name.match(/\[(.*?)\]/)[1]
      const new_qty = event.target.value
      updateCart(cart_item_key, new_qty)
    }
  })

  document.querySelectorAll('.quantity .increment, .quantity .decrement').forEach((button) => {
    button.addEventListener('click', function () {
      const input = this.parentElement.querySelector('.qty')
      const currentQty = parseInt(input.value)
      const newQty = this.classList.contains('increment') ? currentQty + 1 : currentQty - 1
      if (newQty >= 0) {
        input.value = newQty
        const cart_item_key = input.name.match(/\[(.*?)\]/)[1]
        updateCart(cart_item_key, newQty)
      }
    })
  })
})
